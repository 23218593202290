import React from 'react'
import { AnalyticsBrowser } from '@june-so/analytics-next'

export type JuneAnalytics = AnalyticsBrowser | null

const juneCtx = React.createContext<JuneAnalytics>(null)

export const useJuneAnalytics = () => React.useContext(juneCtx)

export const withJuneAnalytics =
  <Props,>(
    BaseComponent: React.ComponentType<Props & { analytics: JuneAnalytics }>
  ) =>
  (props: Props) => {
    const analytics = useJuneAnalytics()
    return <BaseComponent {...props} analytics={analytics} />
  }

export const JuneProvider = ({ children }: { children?: React.ReactNode }) => {
  const [analytics, setAnalytics] = React.useState<JuneAnalytics>(null)

  React.useEffect(() => {
    const loadAnalytics = async () => {
      if (process.env.NEXT_PUBLIC_JUNESO_KEY) {
        const response = AnalyticsBrowser.load({
          writeKey: process.env.NEXT_PUBLIC_JUNESO_KEY,
        })
        setAnalytics(response)
      }
    }

    void loadAnalytics()
  }, [])

  return <juneCtx.Provider value={analytics}>{children}</juneCtx.Provider>
}
