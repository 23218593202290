import '../providers/style/styles.css'
import '../providers/style/fonts/inter.css'
import '../providers/style/fonts/lato.css'
import React, { useState } from 'react'
import { AppProps } from 'next/app'
import ThemeProvider, {
  configureRenderer,
} from '@apps/accounts/src/providers/style/ThemeProvider'
import configureI18n from '@apps/accounts/src/providers/i18n/configure-i18n'
import configureErrorsReporting from '@apps/accounts/src/providers/errors-reporting/configure-errors-reporting'
import configureNetworkClient from '@apps/accounts/src/providers/network/configure'
import { StandardError } from '@packages/common/src/errors/standard-error'
import { useRouter } from 'next/router'
import * as ga from '../utils/ga'
import { configureMixPanel } from '../utils/mixpanel'
import { JuneProvider } from '../providers/analytics/JuneProvider'

configureErrorsReporting()
configureI18n()
configureNetworkClient()
configureMixPanel()

type Props = AppProps & {
  err: any
}

class AppError extends StandardError {}

// renderer both on server & client
const App = ({ Component, pageProps, err }: Props) => {
  const [renderer] = useState(() => configureRenderer())

  if (!renderer) {
    throw new AppError('missing renderer')
  }

  const router = useRouter()

  React.useEffect(() => {
    const handleRouteChange = (url: string) => {
      ga.pageView(url)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router])

  // Workaround for https://github.com/vercel/next.js/issues/8592

  return (
    <ThemeProvider renderer={renderer}>
      <JuneProvider>
        <Component {...pageProps} err={err} />
      </JuneProvider>
    </ThemeProvider>
  )
}

export default App
